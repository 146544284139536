const searchConfig = (searchOptions) => {
  return {
    formItems: [
      {
        type: "input",
        prop: "store_name",
        label: "门店名称",
        placeholder: "请输入商户号",
      },
      {
        type: "input",
        label: "终端ID",
        prop: "device_id",
        placeholder: "请输入终端ID",
      },
      {
        type: "input",
        prop: "agent_id",
        label: "代理ID",
        placeholder: "请输入代理ID",
      },
      {
        type: "select",
        label: "支付方式",
        prop: "payment_method_id",
        options: searchOptions.payTypeList,
      },
      // {
      //   type: "select",
      //   label: "结算通道",
      //   prop: "payment_channel_id",
      //   options: searchOptions.channelList,
      // },
    ],
  };
};



export default searchConfig;
